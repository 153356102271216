import { render, staticRenderFns } from "./priceList.vue?vue&type=template&id=649ffe06&"
import script from "./priceList.vue?vue&type=script&lang=js&"
export * from "./priceList.vue?vue&type=script&lang=js&"
import style0 from "./priceList.vue?vue&type=style&index=0&id=649ffe06&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports