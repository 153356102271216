<template>
    <div class="app-container">
       
        <el-collapse accordion class="pricelistCallapse">

            <div class="topfilter">
                <el-select v-model="value" :placeholder="$t('message.direction')">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    :disabled="item.disabled">
                    </el-option>
                </el-select>
                <el-button type="primary">{{ $t('message.save') }} </el-button>
            </div>

            <el-collapse-item v-for="category in list" :key="category.id" :title="category.name | uppercaseFirst">
                <div class="titleCo pl-4 pr-4">
                    <el-row>
                        <el-col :span="6">
                            {{ columns.name.title }}
                        </el-col>
                        <el-col :span="6">
                            {{ columns.code.title }}
                        </el-col>
                        <el-col :span="6">
                            {{ columns.price.title }}
                        </el-col>
                        <el-col :span="6">
                           {{ columns.discount_price.title }}
                        </el-col>
                    </el-row>
                </div>
                <div class="bodyCo">

                    <el-row class="pl-4 pr-4" v-for="service in category.services" :key="service.id">
                        <el-col :span="6">
                           {{ service.name | uppercaseFirst }}
                        </el-col>
                        <el-col :span="6">
                           {{ service.code }}
                        </el-col>
                        <el-col :span="6">
                            <el-input v-model="service.price"></el-input>
                        </el-col>
                        <el-col :span="6">
                           <el-input v-model="service.discount_price"></el-input>
                        </el-col>
                    </el-row>
                    
                </div>
            </el-collapse-item>
        </el-collapse>

    </div>
</template>
<script>
    import { mapGetters, mapActions } from "vuex";
    export default {
        name: "ServicesPriceList",
        data() {
            return {
                input: 102000,
                input2: 2000,
                options: [{
                    value: 'Option1',
                    label: 'Option1'
                    }, {
                    value: 'Option2',
                    label: 'Option2',
                    disabled: true
                    }, {
                    value: 'Option3',
                    label: 'Option3'
                    }, {
                    value: 'Option4',
                    label: 'Option4'
                    }, {
                    value: 'Option5',
                    label: 'Option5'
                    }
                ],
                    value: ''
            }
        },
        computed:{
            ...mapGetters({
                list: 'priceLists/list',
                money: 'money',
                columns: "services/columns",
            }),
        },
        async mounted(){
            this.fetchData();
        },
        methods: {
            ...mapActions({
                updateList: 'priceLists/index',
                save: 'priceLists/update',
            }),
            fetchData() {
                const query = {};
                if (!this.loadingData) {
                    this.loadingData = true;
                    this.updateList(query).then(res => {
                        this.loadingData = false
                    }).catch(err => {
                        this.loadingData = false
                    });
                }
            },
        }
    }
</script>
<style lang="scss">
    .pricelistCallapse{
        .el-collapse-item .el-collapse-item__header{
            padding: 20px;
        }
    }
    .titleCo{
        border-bottom: 1px solid #EBEEF5;
        padding: 10px 0px;
        font-size: 14px;
        font-weight: 700;
    }
    .bodyCo .el-row{
        border-bottom: 1px solid #EBEEF5;
        padding: 10px 0px;
        font-size: 14px;
        font-weight: 300;
        display: flex;
        align-items: center;
    }
    .bodyCo .el-row .el-input{
        width: 300px !important;
    }
    .topfilter{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
         button{
            width: 200px;;
         }
    }
    .el-select{
        margin-right: 20px;
    }
   
</style>